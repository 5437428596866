<template>
  <div id="container">
    <div id="contents">
      <div class="contents_header">						
      </div>

      <div class="contents_body">		
		<div class="notice-search">
			<div class="inner">
				<!--기본검색 항목-->
				<div class="select_wrap" style="float:left;">
					<select v-model="input.searchCnd"> 
						<option value="0">제목</option>
						<option value="1">내용</option>
					</select>
				</div>
				
				<div class="keyword">
					<input type="text" name="" id="" class="s215" v-model="input.searchWrd" @keyup.enter="search"/>
					<input type="image" src="/images/board/search_icon.png" class="schicon" @click="search();">
				</div>
			</div>
		</div>
        
        <div class="Board">
          <table class="Board_type1">
            <colgroup>
              <col width="120">
              <col width="">
              <col width="150">
            </colgroup>
            <thead>
              <tr>
                <th>NO</th>
                <th>제목</th>
                <th>등록일</th>
              </tr>
            </thead>
            <tbody>
              <!-- 
                bbsNoticeList의 게시물이 bbsList에도 포함되어 있는 경우 
                Duplicate keys detected 에러가 발생하기 때문에 
                bbsNoticeList의 key에는 중복방지를 위해서 앞에 'N'를 붙여줌
              -->
              <tr v-for="bbsNotice in bbsNoticeList" :key="'N'+bbsNotice.nttId">
                <td>
                  <span class="noti">공지</span>
                </td>
                <td class="head over" @click="clickSj(bbsNotice.nttId)">
                  <span>{{ bbsNotice.nttSj }}</span>
                  <span v-if="bbsNotice.commentCnt > 0">&nbsp;({{bbsNotice.commentCnt}})</span>
                  <span v-if="bbsNotice.newYn == 'Y'" class="new">&nbsp;NEW</span>
                  <span v-if="bbsNotice.attaFileCnt > 0" class="fileico"></span>	
				        </td>
				<td>{{ bbsNotice.frstRegistPnttm | moment('YYYY-MM-DD') }}</td>

              </tr>
              <tr v-for="(bbs, index) in bbsList" :key="bbs.nttId">
                <td>{{ (pageInfo.pageIndex - 1) * pageInfo.pageSize + (index + 1) }}</td>
                <td class="head over" @click="clickSj(bbs.nttId)">
                  <span>{{ bbs.nttSj }}</span>
                  <span v-if="bbs.commentCnt > 0">&nbsp;({{bbs.commentCnt}})</span>
                  <span v-if="bbs.newYn == 'Y'" class="new">&nbsp;NEW</span>
                  <span v-if="bbs.attaFileCnt > 0" class="fileico"></span>	
                </td>
                <td>{{ bbs.frstRegistPnttm | moment('YYYY-MM-DD') }}</td>
              </tr>									
            </tbody>
          </table>
        </div>
        
        <!--페이징 컴포넌트 -->
        <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
        <!--// 페이징 컴포넌트 -->

      </div><!--//contents_body-->
    </div><!--//contents-->

  </div><!--//container-->
     
</template>

<script>

import pagingComp from '@/components/PagingComp.vue';

export default {

  components : {
    pagingComp
  },

  data () {
    return {

		input: {
			searchCnd : '0',
			searchWrd : '',
			delYn : ''
		}, // 검색조건		
      
		bbsList : [],
		bbsNoticeList : [],
		pageInfo : {},

		pageIndex : 1,

    }
  },

  beforeCreate() {
    // console.log('beforeCreate');
  },
  created() {
    // console.log('created');
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  mounted() {
    // console.log('mounted');

    this.getBbsList();

  },
  beforeUpdate() {
    // console.log('beforeUpdate');
  },
  updated() {
    // console.log('BBS001M01 update');
  },
  beforeDestroy() {
    // console.log('beforeDestroy');
  },
  destroyed() {
    // console.log('destroyed');
  },    

  methods : {


	search() {
		this.pageIndex = 1;
		this.getBbsList();
	},

    getBbsList() {
     
		this.input.bbsId = '10001';
		this.input.pageIndex = this.pageIndex;
      
		this.$.httpPost('/api/bbs/getBbsList', this.input)
			.then(res => {
				// console.log(res.data);

				this.bbsList = res.data.list;
				this.bbsNoticeList = res.data.noticeList;
				this.pageInfo = res.data.pageInfo;			
			})
			.catch(err => {
				// console.log('============================='); 
				// console.log(err);
				// console.log(err.response);
				// console.log('============================='); 

				alert(err.response.data.error_description);
			});
    },

    clickSj(nttId) {
      this.$router.push('/bbs/BBS001M02?bbsId=10001&nttId=' + nttId);          
    },

    goPage(page) {
      // console.log(page);
      this.pageIndex = page;
      this.getBbsList();
    },

    regBtn() {
      this.$router.push('/bbs/BBS001M03?bbsId=10001');
    },


  }
}
</script>